export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
};

export enum ActionName {
  Add = 'ADD',
  Subtract = 'SUBTRACT'
}

export type AddStopInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
};

export type AddTripToQuoteInput = {
  requestId: Scalars['ID'];
  contactId: Scalars['ID'];
  vehicleId: Scalars['ID'];
  stops: Array<Maybe<AddStopInput>>;
  totalGroupSize: Scalars['Int'];
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddTripToQuotePayload = {
  __typename?: 'AddTripToQuotePayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** This contains driver gratuity charge by route. */
export type AdditionalChargeByRouteInput = {
  routeId: Scalars['ID'];
  /** Should be received as the dollar amount. */
  driverGratuityAmt: Scalars['Float'];
};

export type Airline = {
  __typename?: 'Airline';
  icaoCode: Scalars['String'];
  iataCode?: Maybe<Scalars['String']>;
  airlineName?: Maybe<Scalars['String']>;
  callsign?: Maybe<Scalars['String']>;
  fleetSize?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  countryIso2?: Maybe<Scalars['String']>;
  dateFounded?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AirlinesConnection = {
  __typename?: 'AirlinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirlinesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirlinesEdge = {
  __typename?: 'AirlinesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Airline>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Airport = {
  __typename?: 'Airport';
  icaoCode: Scalars['String'];
  iataCode?: Maybe<Scalars['String']>;
  airportName?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  countryIso2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  gmt?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<Scalars['JSONObject']>;
};

export type AirportCoordsInput = {
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** A connection to a list of items. */
export type AirportsConnection = {
  __typename?: 'AirportsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirportsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirportsEdge = {
  __typename?: 'AirportsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Airport>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Alert = {
  __typename?: 'Alert';
  newDriver: Scalars['Boolean'];
  uniqueLocation: Scalars['Boolean'];
  airport: Scalars['Boolean'];
  largeVehicle: Scalars['Boolean'];
  partner: Scalars['Boolean'];
  luggage: Scalars['Boolean'];
  signage: Scalars['Boolean'];
  carSeat: Scalars['Boolean'];
};

export type AutoPaymentDeposit = {
  __typename?: 'AutoPaymentDeposit';
  /** Sending amt to client in dollars. Amt can be 0 */
  amt?: Maybe<Scalars['Float']>;
  /** Sending amt to client in decimal. Percent can be 0. */
  percent?: Maybe<Scalars['Float']>;
};

export enum AutoPaymentPreference {
  NoCharge = 'NO_CHARGE',
  Deposit = 'DEPOSIT',
  FullCharge = 'FULL_CHARGE'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  /** The ID of an object */
  id: Scalars['ID'];
  bankAccountNumberLast4: Scalars['String'];
  routingNumber: Scalars['String'];
  bankName: Scalars['String'];
  currency: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isInstantPayoutEligible: Scalars['Boolean'];
};

export type BaseRateAutomation = {
  __typename?: 'BaseRateAutomation';
  total: Scalars['Float'];
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
};

export type BaseRateAutomationInput = {
  total: Scalars['Float'];
  lineItems?: Maybe<Array<Maybe<LineItemInput>>>;
};

export enum BaseRateVariantEnum {
  Flat = 'FLAT',
  Hourly = 'HOURLY'
}

export type Billing = Node & {
  __typename?: 'Billing';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  action: ActionName;
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  phone?: Maybe<Scalars['String']>;
  /** Stripe card name */
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type BookingToolStopInput = {
  location: Scalars['String'];
  airport?: Maybe<AirportCoordsInput>;
};

export type CancellationPolicy = Node & {
  __typename?: 'CancellationPolicy';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** @deprecated type is special keyword, variant is our new standard. */
  type: CancellationPolicyVariant;
  variant: CancellationPolicyVariant;
  refund100?: Maybe<Refund>;
  refund50?: Maybe<Refund>;
  refund25?: Maybe<Refund>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum CancellationPolicyVariant {
  Flexible = 'FLEXIBLE',
  Moderate = 'MODERATE',
  Strict = 'STRICT'
}

export enum CardCheckEnum {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Unavailable = 'UNAVAILABLE',
  Unchecked = 'UNCHECKED'
}

export type CardItem = {
  __typename?: 'CardItem';
  /** The ID of an object */
  id: Scalars['ID'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  brand: Scalars['String'];
  country: Scalars['String'];
  cvcCheck?: Maybe<CardCheckEnum>;
  zipCodeCheck?: Maybe<CardCheckEnum>;
};

export type ChildSeatInput = {
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
};

export enum ChildSeatVariant {
  ForwardFacing = 'FORWARD_FACING',
  RearFacing = 'REAR_FACING',
  Booster = 'BOOSTER'
}

export enum CloseOutDisabledEnum {
  AwaitingTripEnd = 'AWAITING_TRIP_END',
  AwaitingFarmeeCloseout = 'AWAITING_FARMEE_CLOSEOUT',
  AwaitingFarmeeAcceptTrip = 'AWAITING_FARMEE_ACCEPT_TRIP'
}

export enum ColorThemeVariant {
  Moovs = 'MOOVS',
  Sky = 'SKY',
  Navy = 'NAVY',
  Lavender = 'LAVENDER',
  Violet = 'VIOLET',
  Rose = 'ROSE',
  Burgundy = 'BURGUNDY',
  Midnight = 'MIDNIGHT',
  Forest = 'FOREST',
  Grass = 'GRASS',
  Ocean = 'OCEAN'
}

export type Comment = Node & {
  __typename?: 'Comment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  bodyText: Scalars['String'];
  user: User;
};

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  contactsTotalCount: Scalars['Int'];
  contacts: Array<Maybe<Contact>>;
  email?: Maybe<Scalars['String']>;
  operator?: Maybe<Operator>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type ConfirmRequestInput = {
  requestId: Scalars['ID'];
  clientSecret?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  additionalChargePerTrip: Array<Maybe<ConfirmRequestTripPriceInput>>;
  /** NOT being used in the resolver or saved to db (for now). */
  cashGratuity?: Maybe<Scalars['Boolean']>;
  printedName?: Maybe<Scalars['String']>;
  intro?: Maybe<Array<Maybe<Scalars['String']>>>;
  terms?: Maybe<Array<Maybe<TermsInput>>>;
  cancellationPolicies?: Maybe<Array<Maybe<Scalars['String']>>>;
  autoPaymentAmt?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConfirmRequestPayload = {
  __typename?: 'ConfirmRequestPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** This value contains driver gratuity and/or meet & greet charge and/or promo code applied by trip. */
export type ConfirmRequestTripPriceInput = {
  tripId: Scalars['String'];
  /** Should be received already as the dollar amount. */
  driverGratuityAmt: Scalars['Float'];
  /** Should be received already as the dollar amount. */
  meetGreetAmt?: Maybe<Scalars['Float']>;
  /** Promo code applied for the trip */
  promoCodeInput?: Maybe<PromoCodeInput>;
  /** Child seat information for the trip */
  childSeatInput?: Maybe<ChildSeatInput>;
};

export enum ConfirmReservationDisabledVariantEnum {
  /** At least trip on the quote does not have a required pricing fields */
  NoPricing = 'NO_PRICING',
  /** At least trip on the quote does not have a vehicle */
  NoVehicle = 'NO_VEHICLE',
  /** Request has been manually archived */
  RequestArchived = 'REQUEST_ARCHIVED',
  /** Request is in the past */
  RequestExpired = 'REQUEST_EXPIRED',
  /** NOTE: Operator can choose to override this, so it shouldn't confirming from operator app. Request is currently within the reservation cutoff period, and therefore it cannot be confirmed by customer via booking tool. */
  ReservationCutoff = 'RESERVATION_CUTOFF'
}

export type Contact = Node & {
  __typename?: 'Contact';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyPosition?: Maybe<Scalars['String']>;
  /** Only returns payment methods that have stripe setup intent status of succeeded. */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Returns all payment methods regardless of stripe setup intent status. */
  allPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  preferences?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  workAddress?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<Scalars['JSON']>;
  /** Returns all contacts that are linked passengers of this contact */
  linkedPassengers: Array<Maybe<Contact>>;
  /** Returns all contacts that this contact is a linked passenger of */
  passengerOf: Array<Maybe<Contact>>;
  /** Returns payment methods that have this contact as the linkedPassengerId */
  passengerLinkedPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type ContactInformationInput = {
  id?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
};

export type CreateContactAndLinkPassengerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactAndLinkPassengerPayload = {
  __typename?: 'CreateContactAndLinkPassengerPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  mobilePhone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoicePaymentInput = {
  invoiceId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInvoicePaymentPayload = {
  __typename?: 'CreateInvoicePaymentPayload';
  invoice: Invoice;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodInput = {
  /** ID of the farmor, always MoovsNetworkOperator */
  farmAffiliateId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  stripePaymentMethod: Scalars['JSON'];
  linkedPassengerId?: Maybe<Scalars['ID']>;
  cardNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRequestInput = {
  orderType?: Maybe<OrderTypeEnum>;
  trips: Array<Maybe<TripInput>>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  gclid?: Maybe<Scalars['String']>;
  queryString?: Maybe<Scalars['String']>;
  bookingContact?: Maybe<ContactInformationInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRequestPayload = {
  __typename?: 'CreateRequestPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReservationInput = {
  orderType?: Maybe<OrderTypeEnum>;
  trips: Array<Maybe<TripInput>>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  clientSecret?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  /** The driver gratuity amount of the first trip. If there is a round trip, this is only the amount for the outbound trip. */
  driverGratuity?: Maybe<Scalars['Float']>;
  /** Only used if there is a return trip. */
  returnDriverGratuity?: Maybe<Scalars['Float']>;
  /** NOT being used in the resolver or saved to db (for now). */
  cashGratuity?: Maybe<Scalars['Boolean']>;
  gclid?: Maybe<Scalars['String']>;
  queryString?: Maybe<Scalars['String']>;
  baseRateAutomation?: Maybe<BaseRateAutomationInput>;
  printedName?: Maybe<Scalars['String']>;
  intro?: Maybe<Array<Maybe<Scalars['String']>>>;
  terms?: Maybe<Array<Maybe<TermsInput>>>;
  cancellationPolicies?: Maybe<Array<Maybe<Scalars['String']>>>;
  autoPaymentAmt?: Maybe<Scalars['Float']>;
  bookingContact?: Maybe<ContactInformationInput>;
  isUnconfirmedRequest?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReservationPayload = {
  __typename?: 'CreateReservationPayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReservationPaymentInput = {
  requestId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  additionalDriverGratuityByRoute?: Maybe<Array<Maybe<AdditionalChargeByRouteInput>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateReservationPaymentPayload = {
  __typename?: 'CreateReservationPaymentPayload';
  payment: Payment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripReviewInput = {
  tripId: Scalars['ID'];
  rating: Scalars['Int'];
  body?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTripReviewPayload = {
  __typename?: 'CreateTripReviewPayload';
  tripReview?: Maybe<TripReview>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWalletInput = {
  contactId?: Maybe<Scalars['ID']>;
  farmAffiliateId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWalletPayload = {
  __typename?: 'CreateWalletPayload';
  clientSecret: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWhiteLabelQuoteInput = {
  orderType?: Maybe<OrderTypeEnum>;
  trips: Array<Maybe<TripInput>>;
  shouldSendEmail?: Maybe<Scalars['Boolean']>;
  gclid?: Maybe<Scalars['String']>;
  /** Operator that will directly handle the order. Used to compare to the white label operator (the operator sourcing the order). */
  recipientOperatorId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWhiteLabelQuotePayload = {
  __typename?: 'CreateWhiteLabelQuotePayload';
  request: Request;
  clientMutationId?: Maybe<Scalars['String']>;
};



export enum DefaultScreenVariant {
  Quotes = 'QUOTES',
  Reservations = 'RESERVATIONS',
  Dispatch = 'DISPATCH',
  DriverTracking = 'DRIVER_TRACKING',
  Invoices = 'INVOICES',
  Payables = 'PAYABLES',
  Dashboard = 'DASHBOARD'
}

export type Driver = Node & {
  __typename?: 'Driver';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  homePhone?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseClass?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  licenseExpirationDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['String']>;
  operator: Operator;
  additionalOperators?: Maybe<Array<Maybe<Operator>>>;
  externalOperator?: Maybe<ExternalOperator>;
  driverProfilePhoto?: Maybe<Scalars['String']>;
  driverAvailability?: Maybe<DriverAvailability>;
  settings?: Maybe<DriverSettings>;
};

export type DriverAvailability = Node & {
  __typename?: 'DriverAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  sundayAvailability: Scalars['Boolean'];
  sundayAvailableStartTime: Scalars['Time'];
  sundayAvailableEndTime: Scalars['Time'];
  mondayAvailability: Scalars['Boolean'];
  mondayAvailableStartTime: Scalars['Time'];
  mondayAvailableEndTime: Scalars['Time'];
  tuesdayAvailability: Scalars['Boolean'];
  tuesdayAvailableStartTime: Scalars['Time'];
  tuesdayAvailableEndTime: Scalars['Time'];
  wednesdayAvailability: Scalars['Boolean'];
  wednesdayAvailableStartTime: Scalars['Time'];
  wednesdayAvailableEndTime: Scalars['Time'];
  thursdayAvailability: Scalars['Boolean'];
  thursdayAvailableStartTime: Scalars['Time'];
  thursdayAvailableEndTime: Scalars['Time'];
  fridayAvailability: Scalars['Boolean'];
  fridayAvailableStartTime: Scalars['Time'];
  fridayAvailableEndTime: Scalars['Time'];
  saturdayAvailability: Scalars['Boolean'];
  saturdayAvailableStartTime: Scalars['Time'];
  saturdayAvailableEndTime: Scalars['Time'];
};

export type DriverSettings = {
  __typename?: 'DriverSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Hourly rate paid to driver for hourly trips (in dollars) */
  hourlyTripHourlyRate?: Maybe<Scalars['Float']>;
  /** Percent of base rate paid to driver for hourly trips */
  hourlyTripBaseRatePct?: Maybe<Scalars['Float']>;
  /** Time used for hourly trips, "location-to-dropoff" or "garage-to-garage". garageToGarage requites driver app tracking */
  hourlyTripLocationsVariant: TripLocationsVariant;
  /** Hourly rate paid to driver for one way trips (in dollars) */
  oneWayTripHourlyRate?: Maybe<Scalars['Float']>;
  /** Minimum number of hours a driver is paid for a one way trip. e.g. if a driver works 20 minutes, but the minumum is 2hrs, then they are paid for 2hrs of work */
  oneWayTripMinimumHours?: Maybe<Scalars['Float']>;
  /** Flat rate paid to drivers on one-way, regardless of number of hours worked (in dollars) */
  oneWayTripFlatRate?: Maybe<Scalars['Float']>;
  /** Percent of base rate paid to driver for one way trips */
  oneWayTripBaseRatePct?: Maybe<Scalars['Float']>;
  /** Time used for one way trips, "location-to-dropoff" or "garage-to-garage". garageToGarage requites driver app tracking */
  oneWayTripLocationsVariant: TripLocationsVariant;
  /** Percent of gratuity allocated for the driver */
  gratuityPct?: Maybe<Scalars['Float']>;
};

export type DriverStatus = {
  __typename?: 'DriverStatus';
  /** The ID of an object */
  id: Scalars['ID'];
  name: DriverStatusName;
  assignedAt?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  declinedAt?: Maybe<Scalars['DateTime']>;
};

export enum DriverStatusName {
  Unassigned = 'UNASSIGNED',
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type DudaWebsite = {
  __typename?: 'DudaWebsite';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  desktopThumbnailUrl?: Maybe<Scalars['String']>;
  previewSiteUrl?: Maybe<Scalars['String']>;
  publishStatus?: Maybe<Scalars['String']>;
  siteDomain?: Maybe<Scalars['String']>;
  /** duda's unique identifier */
  siteName?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  websitePlan?: Maybe<WebsitePlan>;
  websitePermissions?: Maybe<WebsitePermissions>;
};

/** Events related to emails being interacted with sent via sendgrid webhook */
export type EmailEvent = {
  __typename?: 'EmailEvent';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  triggeredAt: Scalars['DateTime'];
  recipient: Scalars['String'];
};

export type EmailLog = Node & {
  __typename?: 'EmailLog';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  emailType: Scalars['String'];
  /** @deprecated use toRecipients to allow multiple to recipients per emailLog Record */
  to?: Maybe<Scalars['String']>;
  toRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  ccRecipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  sendgridResponse?: Maybe<Scalars['JSON']>;
  emailEvents: Array<Maybe<EmailEvent>>;
  confirmationNumber?: Maybe<Scalars['String']>;
};

export type ExternalOperator = Node & {
  __typename?: 'ExternalOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  serviceArea?: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  /** we don't currently support adding photos here */
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  drivers: Array<Maybe<Driver>>;
  vehicles: Array<Maybe<FarmAffiliateVehicle>>;
  /**
   * Cannot attach credit cards to external operators currently.
   *         This keeps the ExternalOperator type in sync with the other farm affiliate types.
   */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

/** Union of all variants of FarmAffiliates, i.e. other operators that you farm rides to and from */
export type FarmAffiliate = ExternalOperator | MoovsNetworkOperator | GriddnetOperator | MoovsAiOperator;

export enum FarmAffiliateVariantEnum {
  MoovsNetworkOperator = 'MoovsNetworkOperator',
  ExternalOperator = 'ExternalOperator',
  GriddnetOperator = 'GriddnetOperator',
  MoovsAiOperator = 'MoovsAiOperator'
}

export type FarmAffiliateVehicle = {
  __typename?: 'FarmAffiliateVehicle';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  available: Scalars['Boolean'];
  capacity: Scalars['Int'];
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  weekendHourlyCost: Scalars['Float'];
  weekdayHourlyCost: Scalars['Float'];
  weekendMinMinutes: Scalars['Float'];
  weekdayMinMinutes: Scalars['Float'];
  photos: Array<Maybe<VehiclePhoto>>;
  features: Array<Maybe<VehicleFeature>>;
  cancellationPolicy?: Maybe<CancellationPolicy>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  vehicleType: VehicleType;
  farmAffiliateName?: Maybe<Scalars['String']>;
  farmAffiliateVariant: FarmAffiliateVariantEnum;
  /** @deprecated use isMoovsLiteOperator. Remove after 1/8/22 */
  isMoovsLightOperator?: Maybe<Scalars['Boolean']>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *     account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
  /** Distance in miles from first stop of the trip being farmed out. */
  distanceFromFirstStop?: Maybe<Scalars['Float']>;
  /** Distance in miles from last stop of the trip being farmed out. */
  distanceFromLastStop?: Maybe<Scalars['Float']>;
  automatedBaseRate?: Maybe<Scalars['Float']>;
  baseRateAutomation?: Maybe<BaseRateAutomation>;
  settings?: Maybe<VehicleSettings>;
};

export enum FarmRelationshipEnum {
  Farmor = 'FARMOR',
  Farmee = 'FARMEE'
}

export enum FarmedRouteStatusEnum {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

export enum FlightStatus {
  Scheduled = 'SCHEDULED',
  Departed = 'DEPARTED',
  Arrived = 'ARRIVED',
  Cancelled = 'CANCELLED',
  Diverted = 'DIVERTED'
}

export enum GettingStartedGoal {
  GrowBusinessOnline = 'GROW_BUSINESS_ONLINE',
  AccessDriverApp = 'ACCESS_DRIVER_APP',
  ExploreOnMyOwn = 'EXPLORE_ON_MY_OWN'
}

export type GoogleDirections = {
  __typename?: 'GoogleDirections';
  estimatedDuration?: Maybe<Scalars['Int']>;
  googleDirectionsResult?: Maybe<Scalars['JSON']>;
};

export type GriddnetOperator = Node & {
  __typename?: 'GriddnetOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas (mirroring MoovsNetworkOperator) */
  serviceArea?: Maybe<Scalars['String']>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  websiteUrl?: Maybe<Scalars['String']>;
  griddnetId: Scalars['String'];
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type InsuranceAttachment = {
  __typename?: 'InsuranceAttachment';
  /** The ID of an object */
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type InsurancePolicy = Node & {
  __typename?: 'InsurancePolicy';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  insuranceCompany: Scalars['String'];
  policyNumber: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  coverageAmount: Scalars['Float'];
  monthlyPremium: Scalars['Float'];
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  insuranceAttachments?: Maybe<Array<Maybe<InsuranceAttachment>>>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  memo?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  amountDue: Scalars['Float'];
  totalAmount: Scalars['Float'];
  isOutstanding: Scalars['Boolean'];
  invoiceNumber: Scalars['String'];
  contact?: Maybe<Contact>;
  /** The Farm Affiliate tied to the invoice. Always a MoovsNetworkOperator. */
  farmAffiliate?: Maybe<FarmAffiliate>;
  company?: Maybe<Company>;
  requests: Array<Maybe<Request>>;
  /** All subpayments made towards this invoice */
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  removedAt?: Maybe<Scalars['DateTime']>;
  emailLogs: Array<Maybe<EmailLog>>;
};

export enum InvoiceStatus {
  Pending = 'PENDING',
  Paid = 'PAID'
}



export enum LagoPlanCode {
  StandardMonthly = 'STANDARD_MONTHLY',
  StandardAnnual = 'STANDARD_ANNUAL',
  ProMonthly = 'PRO_MONTHLY',
  ProAnnual = 'PRO_ANNUAL',
  VipMonthly = 'VIP_MONTHLY',
  VipAnnual = 'VIP_ANNUAL'
}

export type LineItem = {
  __typename?: 'LineItem';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  source: LineItemSourceEnum;
  lineTotal: Scalars['Float'];
};

export type LineItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  source: LineItemSourceEnum;
  lineTotal: Scalars['Float'];
};

export enum LineItemSourceEnum {
  BaseRateAutomation = 'BASE_RATE_AUTOMATION',
  DynamicPricing = 'DYNAMIC_PRICING',
  ZonePricing = 'ZONE_PRICING'
}

export type LinkPassengerToPaymentMethodInput = {
  linkedPassengerId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkPassengerToPaymentMethodPayload = {
  __typename?: 'LinkPassengerToPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type LoadVehiclesConnection = {
  __typename?: 'LoadVehiclesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadVehiclesEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadVehiclesEdge = {
  __typename?: 'LoadVehiclesEdge';
  /** The item at the end of the edge */
  node?: Maybe<Vehicle>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MetadataUnion = TripChangeMetadata;

export type MoovsAiOperator = Node & {
  __typename?: 'MoovsAiOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas (mirroring MoovsNetworkOperator) */
  serviceArea?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type MoovsMarketApp = {
  __typename?: 'MoovsMarketApp';
  /** The ID of an object */
  id: Scalars['ID'];
  chargeAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
};

export type MoovsMarketApps = {
  __typename?: 'MoovsMarketApps';
  /** The ID of an object */
  id: Scalars['ID'];
  driverApp: MoovsMarketApp;
  dynamicPricing: MoovsMarketApp;
  salesAutomation: MoovsMarketApp;
  reviewPlatform: MoovsMarketApp;
  googleCalendar: MoovsMarketApp;
};

export type MoovsNetworkOperator = Node & {
  __typename?: 'MoovsNetworkOperator';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  operatorName: Scalars['String'];
  operatorEmail: Scalars['String'];
  operatorPhone: Scalars['String'];
  operatorAddress?: Maybe<Scalars['String']>;
  /** Moovs operators don't have service areas */
  serviceArea?: Maybe<Scalars['String']>;
  /** name of user who is owner of operator business */
  contactName: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  vehicleTypes: Array<Maybe<VehicleType>>;
  vehicles: Array<Maybe<FarmAffiliateVehicle>>;
  internalNote?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *         account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Update trip information by tripId. */
  updateTrip?: Maybe<UpdateTripPayload>;
  /** Creates a quote for outbound and return trips */
  createRequest?: Maybe<CreateRequestPayload>;
  /** Create and saves stripe payment method if provided, adds driver gratuity & potential meet & greet charge & promo code, if applied */
  confirmRequest?: Maybe<ConfirmRequestPayload>;
  /** Create reservation for outbound and return trips */
  createReservation?: Maybe<CreateReservationPayload>;
  /** Creates a quote for the white label flow. Saves the sourced by (i.e. white-label) operator. */
  createWhiteLabelQuote?: Maybe<CreateWhiteLabelQuotePayload>;
  /** Create a new contact, optionally with credit card */
  createContact?: Maybe<CreateContactPayload>;
  /** Create a new contact and link as passenger. */
  createContactAndLinkPassenger?: Maybe<CreateContactAndLinkPassengerPayload>;
  /**
   * PERMANANTLY deletes a contact by contactId.
   *   Only used for rolling back contact creation as part of createReservation flow.
   */
  rollbackCreateContact?: Maybe<RollbackCreateContactPayload>;
  /** Update a passenger contact record that is link to customer. */
  updatePassengerContact?: Maybe<UpdatePassengerContactPayload>;
  /** Update a single contact */
  updateContact?: Maybe<UpdateContactPayload>;
  /** Remove linked passenger from customer app */
  removeLinkedPassenger?: Maybe<RemoveLinkedPassengerPayload>;
  /** Add a trip to an existing quote */
  addTripToQuote?: Maybe<AddTripToQuotePayload>;
  /** Sends email of solicited trip changes to operator */
  solicitTripChanges?: Maybe<SolicitTripChangesPayload>;
  /** Create a review for a given trip */
  createTripReview?: Maybe<CreateTripReviewPayload>;
  /** Creates a payment for the remaining balance of a request */
  createReservationPayment?: Maybe<CreateReservationPaymentPayload>;
  /** Creates wallet with client secret needed stripe UI element. */
  createWallet?: Maybe<CreateWalletPayload>;
  /** Creates a payment method for a contact or farmAffiliate(farmor) or company */
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  /** Create a payment calculated from it's individual route subpayments */
  createInvoicePayment?: Maybe<CreateInvoicePaymentPayload>;
  /** Link a passenger contact to a payment method on customer app */
  linkPassengerToPaymentMethod?: Maybe<LinkPassengerToPaymentMethodPayload>;
  /** Unlink a passenger from a payment method on customer app */
  unlinkPassengerFromPaymentMethod?: Maybe<UnlinkPassengerFromPaymentMethodPayload>;
};


export type MutationUpdateTripArgs = {
  input: UpdateTripInput;
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationConfirmRequestArgs = {
  input: ConfirmRequestInput;
};


export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};


export type MutationCreateWhiteLabelQuoteArgs = {
  input: CreateWhiteLabelQuoteInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateContactAndLinkPassengerArgs = {
  input: CreateContactAndLinkPassengerInput;
};


export type MutationRollbackCreateContactArgs = {
  input: RollbackCreateContactInput;
};


export type MutationUpdatePassengerContactArgs = {
  input: UpdatePassengerContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationRemoveLinkedPassengerArgs = {
  input: RemoveLinkedPassengerInput;
};


export type MutationAddTripToQuoteArgs = {
  input: AddTripToQuoteInput;
};


export type MutationSolicitTripChangesArgs = {
  input: SolicitTripChangesInput;
};


export type MutationCreateTripReviewArgs = {
  input: CreateTripReviewInput;
};


export type MutationCreateReservationPaymentArgs = {
  input: CreateReservationPaymentInput;
};


export type MutationCreateWalletArgs = {
  input: CreateWalletInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreateInvoicePaymentArgs = {
  input: CreateInvoicePaymentInput;
};


export type MutationLinkPassengerToPaymentMethodArgs = {
  input: LinkPassengerToPaymentMethodInput;
};


export type MutationUnlinkPassengerFromPaymentMethodArgs = {
  input: UnlinkPassengerFromPaymentMethodInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  metadata: MetadataUnion;
};

export type Operator = Node & {
  __typename?: 'Operator';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  generalEmail: Scalars['String'];
  voicePhoneNumber?: Maybe<Scalars['String']>;
  voicePhoneNumberInternational?: Maybe<Scalars['String']>;
  voicePhoneCountryCode: Scalars['String'];
  voicePhoneCountryDialCode: Scalars['String'];
  voicePhoneCountryName: Scalars['String'];
  voicePhoneCountryFormat: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  permitNumber?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
  /** @deprecated no longer used */
  textPhoneNumber?: Maybe<Scalars['String']>;
  widgetBannerUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSONObject']>;
  users: Array<Maybe<User>>;
  automatedEmailsEnabled: Scalars['Boolean'];
  nameSlug?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
  stripeAccountId?: Maybe<Scalars['String']>;
  twilioPhoneNumber?: Maybe<Scalars['JSON']>;
  driverReminderTripReminderTextsEnabled?: Maybe<Scalars['Boolean']>;
  driverStatusUpdateTextsEnabled?: Maybe<Scalars['Boolean']>;
  driverAppAmountDueEnabled: Scalars['Boolean'];
  tripReminderTextsEnabled?: Maybe<Scalars['Boolean']>;
  inboundEmailNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  inboundTextNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  tripCancellationEmailEnabled?: Maybe<Scalars['Boolean']>;
  customDomain?: Maybe<Scalars['String']>;
  customDomainFromName?: Maybe<Scalars['String']>;
  customDomainIsValid?: Maybe<Scalars['Boolean']>;
  customDomainSendgridData?: Maybe<Scalars['JSON']>;
  enableCreditCardWhenBooking?: Maybe<Scalars['Boolean']>;
  enabledOrderTypes: Array<Maybe<OrderType>>;
  /** @deprecated use enabledTripCategories instead. which is nicer b/c it just returns the trip category directly instead of the entire object */
  enabledTripTypes: Array<Maybe<OperatorTripType>>;
  enabledTripCategories: Array<Maybe<TripCategory>>;
  dudaWebsite?: Maybe<DudaWebsite>;
  /** @deprecated removed website user */
  isMoovsAllInOne?: Maybe<Scalars['Boolean']>;
  /**
   * activeForAnalytics field is used to determine whether an operator is paying for a full package (true) or farming
   *     account (false). This field is set by Product team to upgrade an account, so false or null entries indicate Moovs Lite.
   */
  isMoovsLiteOperator?: Maybe<Scalars['Boolean']>;
  bookingCutoffTimeValue: Scalars['Float'];
  bookingCutoffTimeType: TimeDuration;
  gratuityWhenBooking?: Maybe<Scalars['Boolean']>;
  moovsNetworkEnabled?: Maybe<Scalars['Boolean']>;
  gnetId?: Maybe<Scalars['String']>;
  driverReminderPendingJobsTextEnabled?: Maybe<Scalars['Boolean']>;
  driverReminderUpdateTripStatusTextEnabled?: Maybe<Scalars['Boolean']>;
  googleTrackingId?: Maybe<Scalars['String']>;
  /** Custom presets values for driver gratuity selector (e.g. 10%, 15%, 20%). Always returned in ascending order */
  driverGratuityPresets?: Maybe<Array<Maybe<Scalars['Int']>>>;
  customGratuityWhenBooking: Scalars['Boolean'];
  cashGratuityWhenBooking: Scalars['Boolean'];
  moovsChatNumberAsPrimary: Scalars['Boolean'];
  settings: OperatorSettings;
  moovsMarketApps: MoovsMarketApps;
  plan?: Maybe<PlanVariant>;
  onboardingCompleted: Scalars['Boolean'];
  pricingLayout?: Maybe<PricingLayout>;
  requireConsentWhenBooking?: Maybe<Scalars['Boolean']>;
  lagoPlanCode?: Maybe<LagoPlanCode>;
  lagoPaymentMethod: Array<Maybe<PaymentMethod>>;
  gettingStarted?: Maybe<OperatorGettingStarted>;
};

export type OperatorGettingStarted = {
  __typename?: 'OperatorGettingStarted';
  /** The ID of an object */
  id: Scalars['ID'];
  goal: GettingStartedGoal;
  bannerRemovedAt?: Maybe<Scalars['DateTime']>;
  taskFirstVehicleCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstDriverCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstQuoteCompletedAt?: Maybe<Scalars['DateTime']>;
  taskFirstReservationCompletedAt?: Maybe<Scalars['DateTime']>;
  taskMoovsPaymentAccountCompletedAt?: Maybe<Scalars['DateTime']>;
  tasksRemaining?: Maybe<Scalars['Int']>;
};

export type OperatorRoute = Node & {
  __typename?: 'OperatorRoute';
  /** The ID of an object */
  id: Scalars['ID'];
  /** @deprecated Not yet deprecated - aim to use completeOrderNumber in the future */
  orderNumber?: Maybe<Scalars['String']>;
  completeOrderNumber?: Maybe<Scalars['String']>;
  operatorConfirmation?: Maybe<Scalars['String']>;
  /** @deprecated use routeDriver instead */
  driver?: Maybe<Driver>;
  /** driver type w/ availability for route */
  routeDriver?: Maybe<RouteDriver>;
  driverStatus?: Maybe<DriverStatus>;
  trip: Trip;
  request: Request;
  vehicle?: Maybe<Vehicle>;
  operator?: Maybe<Operator>;
  statusSlug: Scalars['String'];
  driverNote?: Maybe<Scalars['String']>;
  operatorNote?: Maybe<Scalars['String']>;
  /** @deprecated Use note in future */
  swoopNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  alert: Alert;
  driverCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  /** Feedback from driver after completion of ride. */
  driverCloseoutNote?: Maybe<Scalars['String']>;
  operatorCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  operatorCloseoutNote?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  gratuityAmt?: Maybe<Scalars['Float']>;
  flatRateAmt?: Maybe<Scalars['Float']>;
  hourlyAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  amountDue?: Maybe<Scalars['Float']>;
  isFarmedRoute: Scalars['Boolean'];
  farmeeCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  farmeeCloseoutNote?: Maybe<Scalars['String']>;
  farmRelationship?: Maybe<FarmRelationshipEnum>;
  /** The Farm Affiliate tied to the route. If Farmee, shows the farmor. If Farmor, shows the farmee. */
  farmAffiliate?: Maybe<FarmAffiliate>;
};

export type OperatorSettings = {
  __typename?: 'OperatorSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  luggageEnabled?: Maybe<Scalars['Boolean']>;
  stopPrice?: Maybe<Scalars['Int']>;
  dynamicPricingRulesCombineEnabled?: Maybe<Scalars['Boolean']>;
  driverFlightUpdatedTextsEnabled: Scalars['Boolean'];
  internalFlightUpdatedTextsEnabled: Scalars['Boolean'];
  pricingLayout?: Maybe<PricingLayout>;
  colorTheme: ColorThemeVariant;
  requireConsentWhenBooking: Scalars['Boolean'];
  requireSignatureWhenBooking: Scalars['Boolean'];
  reviewSite?: Maybe<ReviewSite>;
  reviewSiteUrlPrefix?: Maybe<Scalars['String']>;
  reviewSiteUrlSuffix?: Maybe<Scalars['String']>;
  reviewSiteId?: Maybe<Scalars['String']>;
  reviewSiteTextIncludeLink: Scalars['Boolean'];
  widgetCta: WidgetCta;
  autoPaymentPreference: AutoPaymentPreference;
  autoPaymentDeposit: AutoPaymentDeposit;
  customerChangeAutomationEnabled: Scalars['Boolean'];
  customerChangeAutomationTimeValue: Scalars['Int'];
  customerChangeAutomationTimeType: TimeDuration;
  shuttleLateTextEnabled?: Maybe<Scalars['Boolean']>;
  memberChangedStatusTextEnabled: Scalars['Boolean'];
  billingDetailsNotRequired: Scalars['Boolean'];
  promoCodes: Array<Maybe<PromoCode>>;
  hasActivePromoCodes: Scalars['Boolean'];
  skipVehicleSelectionEnabled: Scalars['Boolean'];
  skipVehicleDefaultVehicleId?: Maybe<Scalars['String']>;
  skipVehicleDefaultVehicle?: Maybe<Vehicle>;
  driverDeclineJobTextEnabled: Scalars['Boolean'];
  driverDeclineJobEmailEnabled: Scalars['Boolean'];
  /** Used to determine the visibility of the quotes tab in the orders page of the customer portal. */
  customerPortalQuotesVisible: Scalars['Boolean'];
  notifyDriverTripChangeEnabled: Scalars['Boolean'];
};

export type OperatorTripType = {
  __typename?: 'OperatorTripType';
  tripType: Scalars['String'];
};

export type OrderType = {
  __typename?: 'OrderType';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  category: Scalars['String'];
};

export enum OrderTypeEnum {
  Airport = 'AIRPORT',
  AirportDropOff = 'AIRPORT_DROP_OFF',
  AirportPickUp = 'AIRPORT_PICK_UP',
  Anniversary = 'ANNIVERSARY',
  BachelorBachelorette = 'BACHELOR_BACHELORETTE',
  Bar = 'BAR',
  BarBatMitzvah = 'BAR_BAT_MITZVAH',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Birthday = 'BIRTHDAY',
  Birthday_21 = 'BIRTHDAY_21',
  BrewTour = 'BREW_TOUR',
  BridalParty = 'BRIDAL_PARTY',
  BrideGroom = 'BRIDE_GROOM',
  BusinessTrip = 'BUSINESS_TRIP',
  Concert = 'CONCERT',
  Corporate = 'CORPORATE',
  CorporateEvent = 'CORPORATE_EVENT',
  DayTour = 'DAY_TOUR',
  FamilyReunion = 'FAMILY_REUNION',
  FieldTrip = 'FIELD_TRIP',
  Football = 'FOOTBALL',
  Funeral = 'FUNERAL',
  Golf = 'GOLF',
  Graduation = 'GRADUATION',
  Hockey = 'HOCKEY',
  Holiday = 'HOLIDAY',
  KidsBirthday = 'KIDS_BIRTHDAY',
  Leisure = 'LEISURE',
  Medical = 'MEDICAL',
  NightOut = 'NIGHT_OUT',
  Other = 'OTHER',
  PersonalTrip = 'PERSONAL_TRIP',
  PointToPoint = 'POINT_TO_POINT',
  PromHomecoming = 'PROM_HOMECOMING',
  Quinceanera = 'QUINCEANERA',
  Retail = 'RETAIL',
  School = 'SCHOOL',
  SchoolFundraiser = 'SCHOOL_FUNDRAISER',
  Seaport = 'SEAPORT',
  SpecialOccasion = 'SPECIAL_OCCASION',
  SportingEvent = 'SPORTING_EVENT',
  Sweet_16 = 'SWEET_16',
  TrainStation = 'TRAIN_STATION',
  Wedding = 'WEDDING',
  WineTour = 'WINE_TOUR'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export enum PaidNotPaid {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID'
}

export type Payment = Node & {
  __typename?: 'Payment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  method: PaymentMethodEnum;
  action: PaymentAction;
  paymentPayer: PaymentPayer;
  /** Holds information about the payment type (largely pertaining to stripe information for cards */
  paymentMethod?: Maybe<PaymentMethod>;
  note?: Maybe<Scalars['String']>;
  paymentStatus: PaymentPaymentStatus;
  didPaymentSucceed: Scalars['Boolean'];
  paymentStatusTooltip?: Maybe<Scalars['String']>;
  refunds?: Maybe<Array<Maybe<PaymentRefund>>>;
  stripeBalanceTransactionId?: Maybe<Scalars['String']>;
  stripePayoutDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  paymentIntent?: Maybe<PaymentIntent>;
  contact?: Maybe<Contact>;
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  invoice?: Maybe<Invoice>;
};

export enum PaymentAction {
  Charge = 'CHARGE',
  Refund = 'REFUND'
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  status?: Maybe<PaymentPaymentStatus>;
  declineCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  net?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  riskEvaluation?: Maybe<Scalars['String']>;
  chargeId?: Maybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Stripe's payment method ID */
  stripeId?: Maybe<Scalars['String']>;
  /** @deprecated Moved to new field payerId, because payment methods are no longer limited to contacts */
  contactId?: Maybe<Scalars['ID']>;
  /** The owner attached to the payment method. Currently supports customer (contact) and farm affiliates (griddnetOperator and moovsNetworkOperator). */
  payerId?: Maybe<Scalars['ID']>;
  /** Stripe customer information */
  customer?: Maybe<Scalars['String']>;
  card?: Maybe<CardItem>;
  cardNote?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
  isPrimary: Scalars['Boolean'];
  /** Simplifies stripe's setup intent statuses into one of pending, rejected, or succeeded for the client. */
  stripeStatus: Scalars['String'];
  linkedPassenger?: Maybe<Contact>;
};

export enum PaymentMethodEnum {
  Card = 'CARD',
  Cash = 'CASH',
  Venmo = 'VENMO',
  Check = 'CHECK',
  Wire = 'WIRE',
  GenericCard = 'GENERIC_CARD'
}

export enum PaymentPayer {
  Customer = 'CUSTOMER',
  Farmor = 'FARMOR',
  Authorization = 'AUTHORIZATION'
}

export enum PaymentPaymentStatus {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Blocked = 'BLOCKED',
  Full = 'FULL',
  Partial = 'PARTIAL',
  DisputeWon = 'DISPUTE_WON',
  DisputeLost = 'DISPUTE_LOST',
  Disputed = 'DISPUTED',
  EarlyFraudWarning = 'EARLY_FRAUD_WARNING',
  EvidenceInReview = 'EVIDENCE_IN_REVIEW',
  Cancelled = 'CANCELLED',
  Uncaptured = 'UNCAPTURED'
}

export type PaymentRefund = Node & {
  __typename?: 'PaymentRefund';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  payment: Payment;
  note?: Maybe<Scalars['String']>;
  action: PaymentAction;
  paymentStatus: PaymentPaymentStatus;
  method?: Maybe<PaymentMethodEnum>;
  stripePayoutDate?: Maybe<Scalars['DateTime']>;
  /** Holds information about the payment type (largely pertaining to stripe information for cards */
  paymentMethod?: Maybe<PaymentMethod>;
  subRefunds?: Maybe<Array<Maybe<SubRefund>>>;
};

export enum PaymentSource {
  Request = 'REQUEST',
  Invoice = 'INVOICE',
  Trip = 'TRIP'
}

export enum PaymentStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  Partial = 'PARTIAL'
}

export enum PayoutSchedule {
  Instant = 'INSTANT',
  Standard = 'STANDARD'
}

export enum PeriodName {
  Week = 'WEEK',
  Day = 'DAY',
  Hour = 'HOUR'
}

export type Permissions = {
  __typename?: 'Permissions';
  canCreateConnectedAccount: Scalars['Boolean'];
};

export type PersonalAvailability = {
  __typename?: 'PersonalAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  available: Scalars['Boolean'];
};

export enum PickUpVariant {
  MeetGreet = 'MEET_GREET',
  Curbside = 'CURBSIDE'
}

export enum PlanVariant {
  Standard = 'STANDARD',
  Pro = 'PRO',
  Vip = 'VIP',
  Free = 'FREE',
  Ai = 'AI'
}

export enum PricelessBookingTarget {
  All = 'ALL',
  Specific = 'SPECIFIC'
}

export type PricingLayout = {
  __typename?: 'PricingLayout';
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  meetGreetAmtEnabled?: Maybe<Scalars['Boolean']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  driverGratuityIsActive?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsActive?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  taxIsActive?: Maybe<Scalars['Boolean']>;
  tollsIsActive?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  otherIsActive?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  /** The ID of an object */
  id: Scalars['ID'];
  promoCodeName: Scalars['String'];
  active: Scalars['Boolean'];
  redemptionCount: Scalars['Int'];
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  totalLimit?: Maybe<Scalars['Int']>;
  bookingContactLimit?: Maybe<Scalars['Int']>;
  bookingStartDate?: Maybe<Scalars['DateTime']>;
  bookingEndDate?: Maybe<Scalars['DateTime']>;
  tripStartDate?: Maybe<Scalars['DateTime']>;
  tripEndDate?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeInput = {
  promoCodeId: Scalars['String'];
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
};

/** The top-level API "/customer" endpoint */
export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches objects given their IDs */
  nodes: Array<Maybe<Node>>;
  /** Fetch operator by operator name slug or duda site name */
  operator: Operator;
  /** Fetch operator route by publicId */
  operatorRoute: OperatorRoute;
  /** Fetch all airports */
  airports?: Maybe<AirportsConnection>;
  /** Fetch all airlines */
  airlines?: Maybe<AirlinesConnection>;
  /** Queries google to get the directions result (for rendering map) and estimated duration */
  googleDirections: GoogleDirections;
  /** Fetch invoice by invoice id */
  invoice?: Maybe<Invoice>;
  /** Fetch available terms by operator */
  terms: Array<Maybe<Term>>;
  /** list of requests for a verified contact */
  requests?: Maybe<RequestsConnection>;
  /** Get user object based on user context */
  me?: Maybe<VerifiedContact>;
  /** Get user object based on user context */
  loadMe?: Maybe<Contact>;
  /** Fetch available cancellation policies */
  cancellationPolicies: Array<Maybe<CancellationPolicy>>;
  /** Fetch all available, non-external vehicles */
  loadVehicles?: Maybe<LoadVehiclesConnection>;
  /** Calculates BRA for a specific vehicle */
  loadVehicleBRA: Vehicle;
  loadWhiteLabelVehicles?: Maybe<Array<Vehicle>>;
  /** Determines if any of the trips for the confirm quote page has a conflict due to vehicle unavailability */
  loadTripConflict?: Maybe<Scalars['Boolean']>;
  /** list of requests for a verified contact */
  loadRequests?: Maybe<LoadRequestsConnection>;
  /** Fetch available cancellation policies for a given operator */
  loadCancellationPolicies: Array<Maybe<CancellationPolicy>>;
  loadPotentialTrackedFlight?: Maybe<Array<Maybe<TrackedFlight>>>;
  /** list of requests for a verified contact */
  loadTripsAsPassenger?: Maybe<LoadTripsAsPassengerConnection>;
  loadPromoCodeValidation: PromoCode;
  /** Fetch stripe connect account receipt for given payment */
  stripePaymentReceiptLink?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** The top-level API "/customer" endpoint */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


/** The top-level API "/customer" endpoint */
export type QueryOperatorArgs = {
  siteName?: Maybe<Scalars['String']>;
  operatorSlug?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryOperatorRouteArgs = {
  publicId: Scalars['String'];
};


/** The top-level API "/customer" endpoint */
export type QueryAirportsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  globalSearch?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryAirlinesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  globalSearch?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryGoogleDirectionsArgs = {
  stops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
};


/** The top-level API "/customer" endpoint */
export type QueryInvoiceArgs = {
  invoiceId: Scalars['String'];
};


/** The top-level API "/customer" endpoint */
export type QueryTermsArgs = {
  operatorSlug?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  afterRequestId?: Maybe<Scalars['ID']>;
  timeFrame?: Maybe<RequestTimeFrameEnum>;
  limit?: Maybe<Scalars['ID']>;
};


/** The top-level API "/customer" endpoint */
export type QueryCancellationPoliciesArgs = {
  operatorSlug: Scalars['String'];
};


/** The top-level API "/customer" endpoint */
export type QueryLoadVehiclesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  stops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
  returnTripStops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
  tripType?: Maybe<Scalars['String']>;
};


/** The top-level API "/customer" endpoint */
export type QueryLoadVehicleBraArgs = {
  vehicleId: Scalars['ID'];
  tripType: Scalars['String'];
  stops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
  returnTripStops?: Maybe<Array<Maybe<StopInformationInputForAutomatedPricing>>>;
};


/** The top-level API "/customer" endpoint */
export type QueryLoadWhiteLabelVehiclesArgs = {
  totalGroupSize: Scalars['String'];
  pickUpStop?: Maybe<BookingToolStopInput>;
  dropOffStop?: Maybe<BookingToolStopInput>;
};


/** The top-level API "/customer" endpoint */
export type QueryLoadTripConflictArgs = {
  requestId: Scalars['ID'];
};


/** The top-level API "/customer" endpoint */
export type QueryLoadRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  timeFrame?: Maybe<RequestTimeFrameEnum>;
  stage?: Maybe<RequestStage>;
};


/** The top-level API "/customer" endpoint */
export type QueryLoadCancellationPoliciesArgs = {
  operatorSlug: Scalars['String'];
};


/** The top-level API "/customer" endpoint */
export type QueryLoadPotentialTrackedFlightArgs = {
  flightNumber: Scalars['Float'];
  firstStopDateTime: Scalars['String'];
  arrivalAirport?: Maybe<Scalars['String']>;
  departureAirport?: Maybe<Scalars['String']>;
  airline: Scalars['String'];
};


/** The top-level API "/customer" endpoint */
export type QueryLoadTripsAsPassengerArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  timeFrame?: Maybe<RequestTimeFrameEnum>;
};


/** The top-level API "/customer" endpoint */
export type QueryLoadPromoCodeValidationArgs = {
  promoCodeName: Scalars['String'];
  pickUpDateTime: Scalars['DateTime'];
};


/** The top-level API "/customer" endpoint */
export type QueryStripePaymentReceiptLinkArgs = {
  paymentId: Scalars['ID'];
};

/** Configuration for refund */
export type Refund = {
  __typename?: 'Refund';
  periodName?: Maybe<PeriodName>;
  periodValue?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
};

export type RemoveLinkedPassengerInput = {
  passengerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveLinkedPassengerPayload = {
  __typename?: 'RemoveLinkedPassengerPayload';
  contact?: Maybe<Contact>;
  passenger?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Request = Node & {
  __typename?: 'Request';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** If all trips are cancelled, returns the date of latest cancelled trip */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** If all trips are closed or cancelled, returns the date of latest farmed route if farmee or closed trip otherwise */
  closedAt?: Maybe<Scalars['DateTime']>;
  activeTripsCount?: Maybe<Scalars['Int']>;
  readyForPayment?: Maybe<Scalars['Boolean']>;
  stage: RequestStage;
  /** @deprecated prefer orderType */
  type?: Maybe<OrderTypeEnum>;
  orderType?: Maybe<OrderTypeEnum>;
  /** Excludes return trips from list of all trips */
  trips: Array<Maybe<Trip>>;
  /** @deprecated use bookingContact. Deprecated on 06/07/2022. */
  teamMembers: Array<Maybe<TeamMember>>;
  bookingContact: Contact;
  orderNumber: Scalars['String'];
  status: RequestStatus;
  emailLogs: Array<Maybe<EmailLog>>;
  invoice?: Maybe<Invoice>;
  /** Request level total amount calculated from it's trips. Before payments have been factored in. */
  totalAmount: Scalars['Float'];
  /** Request level amount due calculated from it's trips */
  amountDue: Scalars['Float'];
  /** Request level payment status determined from it's amount due */
  paymentStatus: PaidNotPaid;
  /** Preferred payment method for this request, appointed by the booking contact */
  preferredPaymentMethod?: Maybe<PaymentMethod>;
  farmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /**
   * All successful sub payments made towards this request.
   *            If operator is farmor this will be customer payments if
   *            operator is farmee it will be sub payments to their farmed route(s)
   */
  combinedSubPayments?: Maybe<Array<Maybe<SubPayment>>>;
  /**
   * All successful refunds made towards this request.
   *            If operator is farmor this will be customer payments if
   *            operator is farmee it will be refunds to their farmed route(s)
   */
  combinedRefunds?: Maybe<Array<Maybe<PaymentRefund>>>;
  /** A Google-generated id used to attribute this request to an ad click */
  gclid?: Maybe<Scalars['String']>;
  /** Currently, white label operator is mainly Swoop operator */
  whiteLabelOperator?: Maybe<Operator>;
  /** If a unconfirmed reservation was declined. */
  isDeclined: Scalars['Boolean'];
  /** If a quote was manually archived. Note: archived on client side also includes "expired" quotes, i.e. trip start date is in the past (see isExpired below). */
  isArchived: Scalars['Boolean'];
  /** If a quote or reservation is in the past. Requires "timezone offset" to be sent from client. Therefore only works on Operator App and Customer App (right now). */
  isExpired?: Maybe<Scalars['Boolean']>;
  /** Sales automation texts, both draft and sent/scheduled texts */
  salesAutomation?: Maybe<SalesAutomation>;
  /** Reasons a quote cannot be confirmed into a reservation. This logic also used for ability to send a quote */
  confirmReservationDisabledVariant?: Maybe<ConfirmReservationDisabledVariantEnum>;
  /** When customer signs during reservation confirmation, a reservation confirmation signature log will be generated */
  reservationConfirmationSignatureLog?: Maybe<ReservationConfirmationSignatureLog>;
  /** Company linked to request */
  company?: Maybe<Company>;
};

export enum RequestStage {
  Quote = 'QUOTE',
  Reservation = 'RESERVATION',
  Unconfirmed = 'UNCONFIRMED'
}

export enum RequestStatus {
  EmailSent = 'EMAIL_SENT',
  Draft = 'DRAFT',
  NewQuote = 'NEW_QUOTE'
}

/** A customer can view their past or current requests (orders) */
export enum RequestTimeFrameEnum {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
  Current = 'CURRENT'
}

/** A connection to a list of items. */
export type RequestsConnection = {
  __typename?: 'RequestsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RequestsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RequestsEdge = {
  __typename?: 'RequestsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ReservationConfirmationSignatureLog = {
  __typename?: 'ReservationConfirmationSignatureLog';
  /** The ID of an object */
  id: Scalars['ID'];
  pdfUrl?: Maybe<Scalars['String']>;
  printedName: Scalars['String'];
  ipAddress: Scalars['String'];
  downloadEnabled: Scalars['Boolean'];
};

export type ReturnTripInput = {
  note?: Maybe<Scalars['String']>;
  totalGroupSize: Scalars['Int'];
  stops: Array<Maybe<StopInformationInput>>;
  routes: Array<Maybe<RouteInput>>;
  contact: ContactInformationInput;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
};

export enum ReviewSite {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Yelp = 'YELP'
}

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type RollbackCreateContactInput = {
  contactId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RollbackCreateContactPayload = {
  __typename?: 'RollbackCreateContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum RoundTripVariant {
  Outbound = 'OUTBOUND',
  Return = 'RETURN'
}

export type Route = Node & {
  __typename?: 'Route';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  groupSize?: Maybe<Scalars['Int']>;
  operatorConfirmation?: Maybe<Scalars['String']>;
  /** Note for operator */
  operatorNote?: Maybe<Scalars['String']>;
  operatorCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  operatorCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  operatorCloseoutNote?: Maybe<Scalars['String']>;
  alert: Alert;
  driverCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  driverCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  /** Determines whether pricing can be adjusted on a route by checking for existence of invoice. This works for both customer invoices from perspective of farmor AND farmedRoute invoices from perspective of farmee */
  canEditPricing: Scalars['Boolean'];
  /** pricing on a route. Does not show for farmee. */
  pricing?: Maybe<RoutePricing>;
  billings: Array<Maybe<Billing>>;
  payments?: Maybe<Array<Maybe<SubPayment>>>;
  publicId?: Maybe<Scalars['String']>;
  /**
   * either driver on route or farmed route
   * @deprecated use routeDriver instead
   */
  driver?: Maybe<Driver>;
  /** driver type w/ availability for route */
  routeDriver?: Maybe<RouteDriver>;
  /** Note for driver */
  driverNote?: Maybe<Scalars['String']>;
  /** Feedback from driver after completion of ride. */
  driverCloseoutNote?: Maybe<Scalars['String']>;
  driverStatus?: Maybe<DriverStatus>;
  driverPayoutFlatRate?: Maybe<Scalars['Float']>;
  driverPayoutHours?: Maybe<Scalars['Float']>;
  driverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  driverPayoutGratuity?: Maybe<Scalars['Float']>;
  automatedDriverPayoutFlatRate?: Maybe<Scalars['Float']>;
  automatedDriverPayoutHours?: Maybe<Scalars['Float']>;
  automatedDriverPayoutRatePerHour?: Maybe<Scalars['Float']>;
  automatedDriverPayoutGratuity?: Maybe<Scalars['Float']>;
  hasAutomatedDriverPayout?: Maybe<Scalars['Boolean']>;
  dispatchStatus: Scalars['String'];
  isFarmedRoute: Scalars['Boolean'];
  farmRelationship?: Maybe<FarmRelationshipEnum>;
  /** Determines whether pricing can be adjusted on a farmed route by checking for existence of invoice. Only relevent if you this route is farmed and the current user is the Farmor. */
  canEditFarmeePricing?: Maybe<Scalars['Boolean']>;
  farmeePricing?: Maybe<RoutePricing>;
  farmAffiliateVariant?: Maybe<FarmAffiliateVariantEnum>;
  /** The Farm Affiliate tied to the route. If Farmee, shows the farmor. If Farmor, shows the farmee. */
  farmAffiliate?: Maybe<FarmAffiliate>;
  /** status of farmed route as it relates to relationship between two operators */
  farmStatus?: Maybe<FarmedRouteStatusEnum>;
  farmoutNote?: Maybe<Scalars['String']>;
  farmeeClosedAt?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutStartDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutEndDateTime?: Maybe<Scalars['DateTime']>;
  farmeeCloseoutDurationMinutes?: Maybe<Scalars['Int']>;
  farmeeCloseoutNote?: Maybe<Scalars['String']>;
  /** Human readable label describing requested vehicle. eg 'Party Bus (15 person)' */
  requestedVehicleNote?: Maybe<Scalars['String']>;
  /** A sub-object containing information about an in-progress route */
  routeDispatch: RouteDispatch;
  isFarmedRouteInvoiced?: Maybe<Scalars['Boolean']>;
  externalConfirmationNumber?: Maybe<Scalars['String']>;
  /** The number of stops for the current route */
  length: Scalars['Int'];
  trip: Trip;
  driverAmountDue?: Maybe<Scalars['Float']>;
  /** Used by driver app, when driver ends trip */
  garageIn?: Maybe<Scalars['DateTime']>;
  /** Used by driver app, when driver starts trip */
  garageOut?: Maybe<Scalars['DateTime']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  /** @deprecated dispatchStatus is more descriptive */
  statusSlug: Scalars['String'];
  /** @deprecated Old swoop related transactions table. Not used */
  transactions: Array<Maybe<Transaction>>;
  /** @deprecated use pricing */
  baseRateAmt?: Maybe<Scalars['Float']>;
};

export type RouteAvailability = {
  __typename?: 'RouteAvailability';
  /** The ID of an object */
  id: Scalars['ID'];
  available: Scalars['Boolean'];
};

export type RouteDispatch = {
  __typename?: 'RouteDispatch';
  /** The ID of an object */
  id: Scalars['ID'];
  previousStopName?: Maybe<Scalars['String']>;
  previousStop?: Maybe<Stop>;
  currentStopName?: Maybe<Scalars['String']>;
  currentStop?: Maybe<Stop>;
  nextStopName?: Maybe<Scalars['String']>;
  nextStop?: Maybe<Stop>;
  currentStatus?: Maybe<RouteDispatchLogStatusEnum>;
  nextStatus?: Maybe<RouteDispatchLogStatusEnum>;
  driverEta?: Maybe<Scalars['DateTime']>;
  currentStopArrivalTime?: Maybe<Scalars['DateTime']>;
};

export enum RouteDispatchLogStatusEnum {
  EnRoute = 'EN_ROUTE',
  Arrived = 'ARRIVED',
  Departed = 'DEPARTED'
}

export type RouteDriver = {
  __typename?: 'RouteDriver';
  /** The ID of an object */
  id: Scalars['ID'];
  driver?: Maybe<Driver>;
  routeAvailability?: Maybe<RouteAvailability>;
  personalAvailability?: Maybe<PersonalAvailability>;
};

export type RouteInput = {
  vehicle: VehicleInput;
  baseRateAmt?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type RoutePricing = {
  __typename?: 'RoutePricing';
  /** The ID of an object */
  id: Scalars['ID'];
  baseRateAmt?: Maybe<Scalars['Float']>;
  /** Flat or hourly base rate. */
  baseRateVariant: BaseRateVariantEnum;
  /**
   * The number of hours for the hourly base rate.
   *           Should be a multiple of 0.25, because we only allow for quarter-hour intervals.
   */
  hourlyBaseRateHours?: Maybe<Scalars['Float']>;
  /** The calculated dollar rate per hour. If null, then base rate variant is flat. */
  hourlyBaseRate?: Maybe<Scalars['Float']>;
  driverGratuityAmt?: Maybe<Scalars['Float']>;
  driverGratuityPercent?: Maybe<Scalars['Float']>;
  promoDiscountAmt?: Maybe<Scalars['Float']>;
  promoDiscountPercent?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  tollsAmt?: Maybe<Scalars['Float']>;
  meetGreetAmt?: Maybe<Scalars['Float']>;
  otherName?: Maybe<Scalars['String']>;
  otherAmt?: Maybe<Scalars['Float']>;
  otherPercent?: Maybe<Scalars['Float']>;
  other2Name?: Maybe<Scalars['String']>;
  other2Amt?: Maybe<Scalars['Float']>;
  other2Percent?: Maybe<Scalars['Float']>;
  other3Name?: Maybe<Scalars['String']>;
  other3Amt?: Maybe<Scalars['Float']>;
  other3Percent?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['ID']>;
  promoCodeName?: Maybe<Scalars['String']>;
  promoCodeAmt?: Maybe<Scalars['Float']>;
  promoCodePercent?: Maybe<Scalars['Float']>;
  forwardFacingSeatQuantity?: Maybe<Scalars['Int']>;
  forwardFacingSeatAmt?: Maybe<Scalars['Float']>;
  rearFacingSeatQuantity?: Maybe<Scalars['Int']>;
  rearFacingSeatAmt?: Maybe<Scalars['Float']>;
  boosterSeatQuantity?: Maybe<Scalars['Int']>;
  boosterSeatAmt?: Maybe<Scalars['Float']>;
  /** Sum of all charges with successful payment on a cancelled trip */
  cancellationFee?: Maybe<Scalars['Float']>;
  /** Sum of all amounts on route. If cancelled, is the cancellation fee. */
  totalAmt?: Maybe<Scalars['Float']>;
  /** Route level payment status determined from it's amount due */
  paymentStatus?: Maybe<PaidNotPaid>;
  /** Amount paid towards total amount */
  amountPaid?: Maybe<Scalars['Float']>;
  driverGratuityIsPct?: Maybe<Scalars['Boolean']>;
  promoDiscountIsPct?: Maybe<Scalars['Boolean']>;
  taxIsPct?: Maybe<Scalars['Boolean']>;
  otherIsPct?: Maybe<Scalars['Boolean']>;
  other2IsPct?: Maybe<Scalars['Boolean']>;
  other3IsPct?: Maybe<Scalars['Boolean']>;
};

export type SalesAutomation = {
  __typename?: 'SalesAutomation';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Reasons that the quote cannot be enrolled in sales automation */
  disabledVariant?: Maybe<SalesAutomationDisabledVariantEnum>;
  /** Utilized within SalesAutomationDialog client side for displaying draft messages. 7 days after 3rd text will potentially be sent or pick-up date - reservation cutoff (whichever is first). */
  autoArchiveDate: Scalars['DateTime'];
  /** Potential texts be sent, prior to user clicking "Enroll" */
  draftTexts?: Maybe<Array<Maybe<SalesAutomationText>>>;
  /** Texts that actually have corresponding records in db. Used in activity log after user clicks "Enroll" */
  texts?: Maybe<Array<Maybe<SalesAutomationText>>>;
  /** The user who enrolled in sales automation (or who is potentially going to enroll). Texts are sent to customer from the perspective of this user. */
  sender: User;
};

export enum SalesAutomationDisabledVariantEnum {
  /** The request has already enrolled in sales automation. Only one sales automation is allowed per request. */
  AlreadyEnrolled = 'ALREADY_ENROLLED',
  /** Request has been archived meaning it was manually archived, but still in the future */
  RequestArchived = 'REQUEST_ARCHIVED',
  /** Request pickup datetime is in the past */
  RequestExpired = 'REQUEST_EXPIRED',
  /** Request is currently within the reservation cutoff period, and therefore it cannot be confirmed by customer via booking tool. */
  ReservationCutoff = 'RESERVATION_CUTOFF',
  /** Operator does not have a moovs chat number, and therefore automated texts cannot send. */
  NoMoovsChatNumber = 'NO_MOOVS_CHAT_NUMBER',
  /** At least trip on the quote does not have a required pricing fields */
  NoPricing = 'NO_PRICING',
  /** At least trip on the quote does not have a vehicle */
  NoVehicle = 'NO_VEHICLE'
}

export type SalesAutomationText = {
  __typename?: 'SalesAutomationText';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The message that is actually sent to user. Includes handlebars template that can be replaced with dynamic values. Null if stage is disabled. */
  content?: Maybe<Scalars['String']>;
  /** Order texts will send in */
  index: Scalars['Int'];
  /** Date the text was sent or is scheduled to send. Null if stage is disabled. */
  sendDate?: Maybe<Scalars['DateTime']>;
  /** Options are DRAFT, SENT, SCHEDULED, ERROR, or DISABLED */
  stage: SalesAutomationTextStageEnum;
  /** Dates of when users clicked the link included in the text */
  impressions?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  /** Person who recieves the automated texts. Always booking contact. But theoeretically could be different person if booking contact is changed after enrolling in sales automation. */
  recipient?: Maybe<Contact>;
};

export enum SalesAutomationTextStageEnum {
  /** Texts that have not yet been scheduled (i.e. client hasn't clicked enroll */
  Draft = 'DRAFT',
  /** Texts that have successfully sent. */
  Sent = 'SENT',
  /** Texts that are scheduled to send. */
  Scheduled = 'SCHEDULED',
  /** Texts that attempted and failed to send. */
  Error = 'ERROR',
  /** Texts that cannot be scheduled to send due to conflict on timing (trip date or reservation cutoff date conflicts with text sendDate) */
  Disabled = 'DISABLED'
}

export type ShuttleRoute = Node & {
  __typename?: 'ShuttleRoute';
  /** The ID of an object */
  id: Scalars['ID'];
  company: Company;
  name: Scalars['String'];
  shuttleRouteStops?: Maybe<Array<Maybe<Stop>>>;
};

export type SolicitTripChangesInput = {
  tripId: Scalars['ID'];
  stops: Array<Maybe<SolicitTripChangesStopInformationInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SolicitTripChangesPayload = {
  __typename?: 'SolicitTripChangesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SolicitTripChangesStopInformationInput = {
  location: Scalars['String'];
  stopIndex?: Maybe<Scalars['Int']>;
  stopId?: Maybe<Scalars['ID']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
};

export type Stop = Node & {
  __typename?: 'Stop';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stopIndex: Scalars['Int'];
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  timezoneId?: Maybe<Scalars['String']>;
  groupSize?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
  /** Photo of places for parking */
  logisticStreetViewUrl?: Maybe<Scalars['String']>;
  /** Common note for everyone */
  note?: Maybe<Scalars['String']>;
  /** Note for operator */
  operatorNote?: Maybe<Scalars['String']>;
  /** Note for driver */
  driverNote?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  flightNumber?: Maybe<Scalars['String']>;
  airport?: Maybe<Airport>;
  airline?: Maybe<Airline>;
  variant?: Maybe<Scalars['String']>;
  /** meet & greet and curbside are availible for airport and non-airport pickups */
  pickUpVariant?: Maybe<PickUpVariant>;
  /** @deprecated now use airport type */
  airportIcao?: Maybe<Scalars['String']>;
  /** @deprecated now use airline type */
  airlineIcao?: Maybe<Scalars['String']>;
  /** if stop is at an airport, this is the flight tracking info */
  trackedFlight?: Maybe<TrackedFlight>;
  /** @deprecated no longer used, use tracked flight only */
  flightData?: Maybe<Scalars['JSON']>;
  /** @deprecated no longer used. hasnt been used for a year but was not removed from client gql queries */
  flightDataUpdatedAt?: Maybe<Scalars['JSON']>;
  coordinates?: Maybe<Scalars['JSON']>;
  stopDispatch?: Maybe<StopDispatch>;
  /** If stop is airport, returns the original intended dateTime set by user */
  originalDateTime?: Maybe<Scalars['DateTime']>;
  /** Return the +/- minutes for a trip that contains a flight */
  flightOffset?: Maybe<Scalars['Int']>;
  /** the dateTime of the pick up for the trip containing this stop. Used for shuttle return trips */
  pickUpDateTime: Scalars['DateTime'];
  /** the timezoneId of the pick up for the trip containing this stop. Used for shuttle return trips */
  pickUpTimezoneId: Scalars['String'];
  /** Alias for location name */
  locationAlias?: Maybe<Scalars['String']>;
};

export type StopDispatch = {
  __typename?: 'StopDispatch';
  /** The ID of an object */
  id: Scalars['ID'];
  enRouteAt?: Maybe<Scalars['DateTime']>;
  arrivedAt?: Maybe<Scalars['DateTime']>;
  departedAt?: Maybe<Scalars['DateTime']>;
};

export type StopInformationInput = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  stopIndex: Scalars['Int'];
  groupSize?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  airportIcao?: Maybe<Scalars['String']>;
  airlineIcao?: Maybe<Scalars['String']>;
  trackedFlight?: Maybe<Scalars['JSON']>;
  variant?: Maybe<Scalars['String']>;
};

export type StopInformationInputForAutomatedPricing = {
  location: Scalars['String'];
  dateTime?: Maybe<Scalars['DateTime']>;
  coordinates?: Maybe<Scalars['JSONObject']>;
  airportIcao?: Maybe<Scalars['String']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  /** The ID of an object */
  id: Scalars['ID'];
  status: StripeAccountStatusEnum;
  statusDescription: Scalars['String'];
  isPayoutsEnabled: Scalars['Boolean'];
  isTreasuryEnabled: Scalars['Boolean'];
  payoutSchedule: PayoutSchedule;
  isAccountSetupComplete: Scalars['Boolean'];
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
};

export enum StripeAccountStatusEnum {
  Complete = 'COMPLETE',
  Enabled = 'ENABLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Restricted = 'RESTRICTED',
  RestrictedSoon = 'RESTRICTED_SOON'
}

export type SubPayment = Node & {
  __typename?: 'SubPayment';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paymentId: Scalars['ID'];
  amount: Scalars['Float'];
  route: Route;
  payment: Payment;
  refunds?: Maybe<Array<Maybe<PaymentRefund>>>;
};

export type SubRefund = Node & {
  __typename?: 'SubRefund';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  route: Route;
};

export type TeamMember = Node & {
  __typename?: 'TeamMember';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  contact: Contact;
};

export type TemporaryPassenger = {
  __typename?: 'TemporaryPassenger';
  name?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  mobilePhoneInternational?: Maybe<Scalars['String']>;
};

export type Term = Node & {
  __typename?: 'Term';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  guideline: Scalars['String'];
};

export type TermsInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export enum TimeDuration {
  Hours = 'HOURS',
  Days = 'DAYS'
}

export type TrackedFlight = Node & {
  __typename?: 'TrackedFlight';
  /** The ID of an object */
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  flightStatus: FlightStatus;
  /** Equivalent to "ident" in flightAware. e.g. UAL301 */
  flightNumber: Scalars['String'];
  /**
   * If codeshare, is the actual flightnumber
   *         tied to the airline actually providing the flight.
   *         Equivalent to "actualIdent" in flightAware. e.g. UAL301
   */
  actualFlightNumber?: Maybe<Scalars['String']>;
  /** id created by FlightAware that tracks an actual flight tied to an actual airplane. */
  faFlightId?: Maybe<Scalars['String']>;
  airline: Airline;
  /** If codeshare, is the actual airline providing the flight. */
  actualAirline?: Maybe<Airline>;
  /** Where the flight departs from. */
  origin: TransitStop;
  /** Where the flight arrives at. */
  destination: TransitStop;
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  type: TransactionTypeEnum;
  totalAmount: Scalars['Float'];
  includedGratuity: Scalars['Float'];
  promoAmount: Scalars['Float'];
  /** @deprecated Use note in future */
  swoopNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  fromContact?: Maybe<Contact>;
  fromOperator?: Maybe<Operator>;
  toOperator?: Maybe<Operator>;
};

export enum TransactionTypeEnum {
  Payment = 'PAYMENT',
  Refund = 'REFUND'
}

/** Info related to origin (departure) or destination (arrival) airport of a flight. */
export type TransitStop = {
  __typename?: 'TransitStop';
  id?: Maybe<Scalars['ID']>;
  airport?: Maybe<Airport>;
  scheduledDateTime: Scalars['DateTime'];
  estimatedDateTime?: Maybe<Scalars['DateTime']>;
  actualDateTime?: Maybe<Scalars['DateTime']>;
  /** Terminal when flight is filed. */
  scheduledTerminal?: Maybe<Scalars['String']>;
  /** Most recent terminal (if different than scheduled) */
  actualTerminal?: Maybe<Scalars['String']>;
  /** Gate when flight is filed. */
  scheduledGate?: Maybe<Scalars['String']>;
  /** Most recent gate (if different than scheduled) */
  actualGate?: Maybe<Scalars['String']>;
};

export type Trip = Node & {
  __typename?: 'Trip';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  requestId: Scalars['ID'];
  tripCategory: TripCategory;
  roundTripVariant?: Maybe<RoundTripVariant>;
  /** Return trips are excluded from list of all trips, they only live on outbound trip */
  returnTrip?: Maybe<Trip>;
  totalGroupSize: Scalars['Int'];
  availableSeats: Scalars['Int'];
  totalDuration?: Maybe<Scalars['Int']>;
  estimatedDuration?: Maybe<Scalars['Int']>;
  googleDirectionsResult?: Maybe<Scalars['JSON']>;
  useTotalDuration?: Maybe<Scalars['Boolean']>;
  timezoneId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  tripNumber?: Maybe<Scalars['String']>;
  stops: Array<Maybe<Stop>>;
  routes: Array<Maybe<Route>>;
  contact?: Maybe<Contact>;
  /** Billings table records tied to the trip. No new records should be added after request_stage_field migration */
  billings?: Maybe<Array<Maybe<Billing>>>;
  subPayments?: Maybe<Array<Maybe<SubPayment>>>;
  /** total amount of all route pricing */
  totalAmount: Scalars['Float'];
  /** Remaining amount left to be paid. */
  amountDue: Scalars['Float'];
  /** payment status for whole trip */
  paymentStatus: PaymentStatus;
  tempPassenger?: Maybe<TemporaryPassenger>;
  /** @deprecated switching to tempPassenger, so that we can have a type instead of just JSON */
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  closedAt?: Maybe<Scalars['DateTime']>;
  farmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /** overall farm status of all trips except current one */
  otherTripsFarmedRouteStatus?: Maybe<FarmedRouteStatusEnum>;
  /**
   * Disabled if...
   *         1. stops are before today's date.
   *         OR
   *         2. operator is farmor AND farmee has not closed out the trip.
   *         If null, then close out is NOT disabled.
   */
  closeOutDisabledVariant?: Maybe<CloseOutDisabledEnum>;
  reopenDisabled?: Maybe<Scalars['Boolean']>;
  request: Request;
  isTripInvoiced?: Maybe<Scalars['Boolean']>;
  canAutomateCustomerChange: Scalars['Boolean'];
  shuttleRoute?: Maybe<ShuttleRoute>;
  childSeats: Scalars['JSON'];
  notification?: Maybe<Notification>;
};

export enum TripCategory {
  OneWay = 'ONE_WAY',
  Hourly = 'HOURLY',
  RoundTrip = 'ROUND_TRIP',
  ShuttlePickUp = 'SHUTTLE_PICK_UP',
  ShuttleReturn = 'SHUTTLE_RETURN'
}

export type TripChangeMetadata = {
  __typename?: 'TripChangeMetadata';
  orderTypePrevious?: Maybe<OrderTypeEnum>;
  driverNotePrevious?: Maybe<Scalars['String']>;
  vehicleIdPrevious?: Maybe<Scalars['ID']>;
  forwardFacingSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  rearFacingSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  boosterSeatQuantityPrevious?: Maybe<Scalars['Int']>;
  swoopNotePrevious?: Maybe<Scalars['String']>;
  stopsPrevious?: Maybe<Array<Maybe<Stop>>>;
};

export type TripInput = {
  note?: Maybe<Scalars['String']>;
  totalGroupSize: Scalars['Int'];
  stops: Array<Maybe<StopInformationInput>>;
  routes: Array<Maybe<RouteInput>>;
  contact: ContactInformationInput;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  returnTrip?: Maybe<ReturnTripInput>;
};

export enum TripLocationsVariant {
  GarageToGarage = 'GARAGE_TO_GARAGE',
  LocationDropOff = 'LOCATION_DROP_OFF'
}

export type TripReview = {
  __typename?: 'TripReview';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  trip: Trip;
  /** 1 to 5 */
  rating: Scalars['Int'];
  body?: Maybe<Scalars['String']>;
  reviewSite?: Maybe<ReviewSite>;
};

export type UnlinkPassengerFromPaymentMethodInput = {
  paymentMethodId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkPassengerFromPaymentMethodPayload = {
  __typename?: 'UnlinkPassengerFromPaymentMethodPayload';
  paymentMethod: PaymentMethod;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  preferences?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePassengerContactInput = {
  passengerId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePassengerContactPayload = {
  __typename?: 'UpdatePassengerContactPayload';
  contact?: Maybe<Contact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripInput = {
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  temporaryPassenger?: Maybe<Scalars['JSON']>;
  totalGroupSize?: Maybe<Scalars['Int']>;
  route?: Maybe<UpdateTripRouteInput>;
  contactId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripPayload = {
  __typename?: 'UpdateTripPayload';
  trip?: Maybe<Trip>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTripRouteInput = {
  id: Scalars['ID'];
  carryOnLuggage?: Maybe<Scalars['Int']>;
  checkedLuggage?: Maybe<Scalars['Int']>;
  oversizeLuggage?: Maybe<Scalars['Int']>;
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  emailNormalize: Scalars['String'];
  mobilePhone: Scalars['String'];
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phoneCountryDialCode: Scalars['String'];
  phoneCountryName: Scalars['String'];
  phoneCountryFormat: Scalars['String'];
  invitePending: Scalars['Boolean'];
  role: Role;
  operatorId: Scalars['ID'];
  operator: Operator;
  permissions: Permissions;
  accessPermissions: Array<Maybe<UserAccessPermission>>;
  settings: UserSetting;
  /** @deprecated Onboarding should be completed based off of operator, not user. Moving to Operator. */
  onboardingCompleted?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use settings.tripsViewConfig, encapsulating all user settings under UserSettingsType */
  tripsViewConfig?: Maybe<Scalars['JSON']>;
};

export type UserAccessPermission = Node & {
  __typename?: 'UserAccessPermission';
  /** The ID of an object */
  id: Scalars['ID'];
  path: Scalars['String'];
  category: Scalars['String'];
  name: Scalars['String'];
  grantedByDefault: Scalars['Boolean'];
};

export type UserSetting = {
  __typename?: 'UserSetting';
  /** The ID of an object */
  id: Scalars['ID'];
  tripsViewConfig?: Maybe<Scalars['JSON']>;
  defaultScreen?: Maybe<DefaultScreenVariant>;
};

export type Vehicle = Node & {
  __typename?: 'Vehicle';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  available: Scalars['Boolean'];
  capacity: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photos: Array<Maybe<VehiclePhoto>>;
  features: Array<Maybe<VehicleFeature>>;
  operator: Operator;
  /** @deprecated use vehicleType */
  type: VehicleType;
  vehicleType: VehicleType;
  licensePlate?: Maybe<Scalars['String']>;
  weekendHourlyCost?: Maybe<Scalars['Float']>;
  weekdayHourlyCost?: Maybe<Scalars['Float']>;
  weekendMinMinutes?: Maybe<Scalars['Float']>;
  weekdayMinMinutes?: Maybe<Scalars['Float']>;
  vinNumber?: Maybe<Scalars['String']>;
  exteriorColor?: Maybe<Scalars['String']>;
  cancellationPolicy?: Maybe<CancellationPolicy>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  minimumTotalBaseRate?: Maybe<Scalars['Float']>;
  deadheadRatePerMile?: Maybe<Scalars['Float']>;
  tripRatePerMile?: Maybe<Scalars['Float']>;
  totalDeadheadDurationMinutes?: Maybe<Scalars['Int']>;
  enableBaseRateAutomation?: Maybe<Scalars['Boolean']>;
  enableBaseRateAutomationBookingTool?: Maybe<Scalars['Boolean']>;
  publishedToDudaSite?: Maybe<Scalars['Boolean']>;
  automatedBaseRate?: Maybe<Scalars['Float']>;
  /** Only exists on create new request for customer app. */
  returnAutomatedBaseRate?: Maybe<Scalars['Float']>;
  baseRateAutomation?: Maybe<BaseRateAutomation>;
  returnBaseRateAutomation?: Maybe<BaseRateAutomation>;
  externalOperator?: Maybe<ExternalOperator>;
  settings?: Maybe<VehicleSettings>;
  /** If null this vehicle was not checked for conflicts */
  hasTripConflict?: Maybe<Scalars['Boolean']>;
};

export type VehicleChildSeat = Node & {
  __typename?: 'VehicleChildSeat';
  /** The ID of an object */
  id: Scalars['ID'];
  type: ChildSeatVariant;
  active: Scalars['Boolean'];
  quantity: Scalars['Int'];
  amt: Scalars['Float'];
  imageUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type VehicleFeature = Node & {
  __typename?: 'VehicleFeature';
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
};

export type VehicleInput = {
  id: Scalars['ID'];
};

export type VehiclePhoto = Node & {
  __typename?: 'VehiclePhoto';
  /** The ID of an object */
  id: Scalars['ID'];
  url: Scalars['String'];
  photoIndex: Scalars['Int'];
};

export type VehicleSettings = {
  __typename?: 'VehicleSettings';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Array of weekends represented as objects */
  weekends?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  conflictBlockQuote: Scalars['Boolean'];
  conflictBlockReservation: Scalars['Boolean'];
  forwardFacingSeat: VehicleChildSeat;
  rearFacingSeat: VehicleChildSeat;
  boosterSeat: VehicleChildSeat;
  pricelessBookingEnabled: Scalars['Boolean'];
  /** Priceless booking will either be available to all or specific contacts */
  pricelessBookingTarget: PricelessBookingTarget;
  pricelessBookingCompanies?: Maybe<Array<Maybe<Company>>>;
  pricelessBookingContacts?: Maybe<Array<Maybe<Contact>>>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  typeName: Scalars['String'];
  typeSlug: Scalars['String'];
  iconCategory: Scalars['String'];
};

/**
 * A verifiedContact is a contact who has created an account through
 *     the customer app or was created by an operator and then logged into the customer app.
 *     A verified contact is not considered verified until they actually verify through
 *     twilio (which is stored in the 'mobile_phone_verified_at' field. Every verifiedContact is
 *     tied to an actual contact in operator app. But not every contact is a verified contact.
 */
export type VerifiedContact = {
  __typename?: 'VerifiedContact';
  /** The ID of an object */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** mobile phone value used everywhere except international inputs */
  mobilePhone: Scalars['String'];
  /** mobile phone used in international inputs */
  mobilePhoneInternational: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneCountryDialCode?: Maybe<Scalars['String']>;
  phoneCountryName?: Maybe<Scalars['String']>;
  phoneCountryFormat?: Maybe<Scalars['String']>;
  operatorId: Scalars['ID'];
  /** null unless phone used for auth differs from phone attached to contact */
  authMobilePhone?: Maybe<Scalars['String']>;
  /** Only returns payment methods that have stripe setup intent status of succeeded. */
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  stripeCustomer?: Maybe<Scalars['JSON']>;
};

export enum WebsitePermissions {
  Basic = 'BASIC',
  Premium = 'PREMIUM'
}

export enum WebsitePlan {
  Free = 'FREE',
  Paid = 'PAID'
}

export enum WidgetCta {
  BookNow = 'BOOK_NOW',
  GetAQuote = 'GET_A_QUOTE'
}

/** A connection to a list of items. */
export type LoadRequestsConnection = {
  __typename?: 'loadRequestsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadRequestsEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadRequestsEdge = {
  __typename?: 'loadRequestsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Request>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** A connection to a list of items. */
export type LoadTripsAsPassengerConnection = {
  __typename?: 'loadTripsAsPassengerConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadTripsAsPassengerEdge>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadTripsAsPassengerEdge = {
  __typename?: 'loadTripsAsPassengerEdge';
  /** The item at the end of the edge */
  node?: Maybe<Trip>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};
